import * as yup from "yup";
import { modifyOptionType, OrderItemStatus, UnitType } from "../enums";
import { MAX_ORDER_ITEM_NOTE_LENGTH } from "../settings";
import utcIsoDateString from "./Date";
import MapSchema from "./MapSchema";

export const orderItemNotesSchema = yup.object({
  operator: yup.string().max(MAX_ORDER_ITEM_NOTE_LENGTH).notRequired(),
  liable: yup.string().max(MAX_ORDER_ITEM_NOTE_LENGTH).notRequired(),
  cancel: yup.string().max(MAX_ORDER_ITEM_NOTE_LENGTH).notRequired(),
});

export const orderItemModifiersSchema = MapSchema(
  MapSchema(
    yup.object({
      cost: yup.number().required().min(0),
      type: yup.lazy((value) => {
        if (typeof value === "string") {
          return yup.string().oneOf(Object.keys(modifyOptionType)).required();
        } else {
          return yup.number().required().min(0);
        }
      }),
    })
  )
);

export const orderItemMetaSchema = yup
  .object({
    catId: yup.string().notRequired(),
    round: yup.number().optional().default(undefined).nullable(),
    pmId: yup.string().notRequired(),
    localId: yup.string().notRequired(),
    customerId: yup.string(),
    basePrice: yup.number().min(0).max(10000),
    customName: yup.string(),
    taxIds: yup.array().of(yup.string()).nullable().optional().default(null),
    canceledBy: yup.string().nullable().notRequired(),
  })
  .required();

export const orderItemSubGroupSchema = yup
  .object({
    itemId: yup.string().nullable(),
    cost: yup.number().min(0),
    hold: yup.string().nullable(),
    modifiers: orderItemModifiersSchema,
    notes: orderItemNotesSchema.required(),
  })
  .required();

export const orderItemUnitTypeSchema = yup
  .mixed<UnitType>()
  .oneOf(Object.values(UnitType));

export const orderItemAmountSchema = yup.number().min(0.0001).max(500);

export const orderItemStatusSchema = yup
  .mixed<OrderItemStatus>()
  .oneOf(Object.values(OrderItemStatus));

export const orderItemUnitSchema = yup.object({
  type: orderItemUnitTypeSchema.required().default(UnitType.unit),
});

export const orderItemSubItemGroupsSchema = MapSchema(orderItemSubGroupSchema);

export const orderItemSchema = yup
  .object({
    id: yup.string().notRequired(),
    sessionId: yup.string(),
    status: orderItemStatusSchema,
    liableId: yup.string(),
    modifiers: orderItemModifiersSchema,
    subItemGroups: orderItemSubItemGroupsSchema.notRequired(),
    itemId: yup.string().required(),
    meta: orderItemMetaSchema,
    notes: orderItemNotesSchema.required(),
    amount: orderItemAmountSchema.required(),
    unit: orderItemUnitSchema.notRequired(),
    ppu: yup.number().min(0),
    cost: yup.number().min(0),
    addedAt: utcIsoDateString.notRequired(),
    createdAt: utcIsoDateString.notRequired(),
    updatedAt: utcIsoDateString.notRequired(),
  })
  .required();

export type TOrderItem = yup.InferType<typeof orderItemSchema>;

export type TOrderItemSubGroups = yup.InferType<
  typeof orderItemSubItemGroupsSchema
>;

export type TOrderItemSubGroup = yup.InferType<typeof orderItemSubGroupSchema>;

export type TOrderItemMeta = yup.InferType<typeof orderItemMetaSchema>;

export type TOrderItemNotes = yup.InferType<typeof orderItemNotesSchema>;

export type TOrderItemModifiers = yup.InferType<
  typeof orderItemModifiersSchema
>;
