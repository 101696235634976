import Big from "big.js";
import cuid from "cuid";
import {
  BookingStatus,
  OrderItemStatus,
  SessionState,
  SessionTypes,
  SessionUserRole,
  TipModes,
  TipStatus,
  discountType,
  modifierStyle,
  paymentMethodTypes,
  transactionAction,
  transactionStatus,
} from "./enums";
import {
  CONSUMER_ACTIVE_SESSION_STATES,
  DEFAULT_PLACE_BOOKING,
  DEFAULT_TIMEZONE,
  FINAL_TRNSACTION_ACTIONS,
  HOURS_ALL_DAY_CLOSES,
  HOURS_ALL_DAY_OPEN,
  IGNORE_ORDER_ITEM_STATUS,
  JS_WEEKDAYS_INDEX,
  SESSION_STATE_ENDED,
  isSessionAcceptConsumerMutations,
  isSessionIngoing,
} from "./settings";
import { CashTransactionPayload } from "./types";
import { TBooking, bookingSchema } from "./yup/Booking";
import {
  TBusinessAccept,
  TBusinessTax,
  TBusinessTaxRule,
} from "./yup/Business";
import { TItem } from "./yup/Item";
import { TModifier } from "./yup/ItemModifier";
import { TPlace } from "./yup/Place";
import { TPromo, TPromoFixBenfit, TPromoPercentBenfit } from "./yup/Promo";
import { TSchedule } from "./yup/Schedule";
import {
  TSession,
  TSessionDiscount,
  TSessionDocument,
  TSessionTransaction,
  sessionDiscountSchema,
} from "./yup/Session";
import { TBusinessControl, TUser } from "./yup/User";
import { TOrderItem, TOrderItemModifiers } from "./yup/OrderItem";
import {
  isAfter,
  isBefore,
  startOfToday,
  max,
  format,
  addDays,
  isPast,
  startOfDay,
  addMinutes,
  differenceInMilliseconds,
  endOfDay,
  setHours,
  set,
  add,
  addMilliseconds,
  subMilliseconds,
} from "date-fns";
import { isValid } from "date-fns";
import {
  toZonedTime,
  toDate,
  fromZonedTime,
  getTimezoneOffset,
} from "date-fns-tz";

/**
 * status equals complete
 * @param transaction
 */
export const isTransactionCompleted = (transaction: any) =>
  transaction.status == transactionStatus.COMPLETE;

export const isAuthTransaction = (transaction: any) =>
  transaction.action == transactionAction.AUTHORIZE;

export const isTempSessionId = (id: string) =>
  typeof id === "string" ? id.startsWith("@") : true; // if not string, then it's temp

/**
 * action charge or refund transaction;
 * @param transaction
 */
export const isFinalTransaction = (transaction) =>
  FINAL_TRNSACTION_ACTIONS.includes(transaction.action);

export const isTransactionMethodRequiresIntegration = (method) =>
  ![paymentMethodTypes.CASH, paymentMethodTypes.OTHER].includes(method);

export const validateTransactionMethodSupportAction = ({ method, action }) => {
  if (
    method === paymentMethodTypes.CASH ||
    method === paymentMethodTypes.OTHER
  ) {
    if (FINAL_TRNSACTION_ACTIONS.includes(action)) {
      return;
    } else {
      throw new Error(`cannot use ${method} to ${action}`);
    }
  } else if (method === paymentMethodTypes.CARD) {
    if (
      [
        transactionAction.CHARGE,
        transactionAction.REFUND,
        transactionAction.AUTHORIZE,
      ].includes(action)
    ) {
      return;
    } else {
      throw new Error(`cannot use ${method} to ${action}`);
    }
  } else {
    throw new Error(`unkown method ${method} `);
  }
};

export const getOrderItemNameHelper = ({ oi, item }) => {
  if (item.operator.customName && oi.meta.customName) return oi.meta.customName;
  return item.displayName || item.name;
};

export const getTransactionAcceptance = ({
  isOperator,
  accepts,
  acceptId,
  action,
  method,
}: {
  acceptId?: string;
  isOperator: boolean;
  accepts: TBusinessAccept[];
  action: transactionAction;
  method: paymentMethodTypes;
}) => {
  return accepts.find((accept) => {
    if (!accept.active) return false; // is active
    if (method !== accept.method) return false; // same method
    if (accept.operator?.only && !isOperator) return false; // current can apply
    if (acceptId && acceptId !== accept.id) return false;
    return true;
  });
};

export const isOperatorRole = (role) => SessionUserRole.operator === role;

export const round = (value, degits = 2) => {
  const multi = Math.pow(10, degits);
  return Math.round(value * multi) / multi;
};

export const calcModifires = (oim: TOrderItemModifiers) => {
  let cost = Big(0);

  if (!oim || typeof oim !== "object") return cost.toNumber();

  for (let mid in oim) {
    const mod = oim[mid];
    if (typeof mod !== "object" || !mod) continue;
    for (let oid in mod) {
      const opt = mod[oid];
      if (typeof opt !== "object" || !opt) continue;

      if (typeof opt.cost === "number" && !isNaN(opt.cost)) {
        if (typeof opt.type === "number" && isNaN(opt.type) === false) {
          cost = cost.add(Big(opt.cost).mul(opt.type));
        } else {
          cost = cost.add(opt.cost);
        }
      }
    }
  }

  return cost.toNumber();
};

export const createPromoDiscount = (
  promo: TPromo,
  isServer: boolean = false
): TSessionDiscount => {
  const discount = sessionDiscountSchema.cast({
    id: cuid(),
    promoId: promo.id,
    type: promo.type === "fixed" ? discountType.FIX : discountType.PERCENT,
    auto: promo.trigger === "auto",
    settings: { orderItems: [] },
    createdAt: isServer ? new Date().toISOString() : undefined,
  } as Partial<TSessionDiscount>);

  if (promo.type === "fixed") {
    const benfit = promo.benfit as TPromoFixBenfit;
    discount.amount = benfit.value;
  } else if (promo.type === "percent") {
    const benfit = promo.benfit as TPromoPercentBenfit;
    discount.amount = benfit.value;
    discount.limit = benfit.limitValue;
  }

  return discount;
};

export const applyAutoPromotionDiscounts = ({
  promotions,
  session,
  items,
  isServer = false,
  timezone = DEFAULT_TIMEZONE,
}: {
  promotions: TPromo[];
  session: TSession;
  items: Record<string, TItem>;
  isServer?: boolean;
  timezone?: string;
}) => {
  const promoCodeIds: string[] =
    session?.meta?.promos?.map?.((sp) => sp.promoId!) || [];

  let relevants = 0;

  for (let promo of promotions) {
    if (
      promo.trigger === "code" &&
      !session.meta.promos?.find?.((sp) => sp.promoId === promo.id)
    ) {
      // this promo required by code only, and if not applied so we need to skip it
      continue;
    }

    if (Array.isArray(promo.target.sessionTypes)) {
      if (!promo.target.sessionTypes.includes(session.type)) {
        // not relevant for this session type
        // console.log("promo not relevant for this session type");
        continue;
      }
    }

    let d = session.discounts.findIndex(
      (discount) => discount.promoId === promo.id
    );

    const exists = d !== -1;

    if (promo.trigger === "manual") {
      if (!exists) {
        // this promo is manually applied, so we need to skip it if not exists
        continue;
      }
    }

    let discount = exists
      ? session.discounts[d]
      : createPromoDiscount(promo, isServer);

    if (["fixed", "percent"].includes(promo.type)) {
      for (let oi of session.orderItems) {
        const item = items[oi.itemId];

        if (promo.target.noCustomer && oi.meta.customerId) {
          continue;
        }

        if (promo.target.noOperator && !oi.meta.customerId) {
          continue;
        }

        // qualify
        const valid = filterItemPromotion({
          item,
          promo,
          at: oi.createdAt || new Date().toISOString(),
          promoCodeIds,
          discounts: session?.discounts,
          timezone,
        });

        if (valid) {
          // apply
          if (!discount.settings) discount.settings = { orderItems: [] };
          if (!discount.settings.orderItems) discount.settings.orderItems = [];

          const oiId = oi.id || oi.meta?.localId!;

          if (!discount.settings.orderItems.includes(oiId)) {
            relevants++;
            discount.settings.orderItems.push(oiId);
          }
        }
      }
    } else {
      continue;
    }

    if (!exists && relevants > 0) {
      // console.log("pushing discount to session", discount);
      session.discounts.push(discount);
    }
  }
};

export const calcOrderItemCost = (oi: Partial<TOrderItem>): number => {
  try {
    let additionals = Big(oi?.modifiers ? calcModifires(oi.modifiers) : 0);

    for (let sgId in oi.subItemGroups) {
      additionals = additionals.add(oi.subItemGroups?.[sgId]?.cost || 0);
      additionals = additionals.add(
        calcModifires(oi.subItemGroups?.[sgId]?.modifiers) || 0
      );
    }

    if (isNaN(oi.ppu as any)) {
      throw new Error("order item ppu not exist");
    }

    const cost = Big(oi?.ppu || 0)
      .add(additionals || 0)
      .mul(oi.amount || 0)
      .toNumber();

    return cost;
  } catch (error) {
    console.error(error);
    return NaN;
  }
};
type CheckTaxType = { name: string; value: number };

const calcTax = (value, rate) => {
  if (!rate || value?.eq?.(0) || !value) return Big(0);

  return Big(value)
    .mul(100)
    .div(Big(rate).mul(100).add(100))
    .minus(value)
    .mul(-1)
    .round(2, Big.roundUp);
};

export enum CheckScope {
  pending = "pending",
  eligble = "eligble",
  all = "all",
}

export enum CheckEntity {
  operator = "operator",
  consumer = "consumer",
}

type CalcCheckOptions = {
  liableId?: string;
  scope?: CheckScope;
  entity?: CheckEntity;
};

const defaultCheckOptions = {
  scope: CheckScope.eligble,
};

const matchCheckOptions = (
  {
    liableId,
    consumer,
    scope,
  }: {
    liableId: string;
    consumer: boolean;
    scope?: CheckScope;
  },
  options: CalcCheckOptions
) => {
  // scope asked is different
  if (options.scope && options.scope !== CheckScope.all) {
    if (scope && options.scope !== scope) {
      return false;
    }
  }

  // asked consumer, not consumer
  if (options.entity == CheckEntity.consumer && !consumer) {
    return false;
  }

  // asked operator, is consumer
  if (options.entity == CheckEntity.operator && consumer) {
    return false;
  }

  // liable asked is different
  if (liableId && options.liableId && options.liableId !== liableId) {
    return false;
  }

  return true;
};

// todo: rewrite createSnapshot
export const jsonClone = <T>(x: T): T => JSON.parse(JSON.stringify(x));

export const findExamptionRule = (
  tax: TBusinessTax,
  options: {
    placeId?: string;
    catId?: string;
    sessionType?: SessionTypes;
    segment?: "tip" | "serviceCharge" | "deliveryFee" | "orderItem";
  }
): null | TBusinessTaxRule => {
  if (!Array.isArray(tax.rules)) {
    // console.log("no rules provided");
    return null;
  }

  for (let rule of tax.rules) {
    if (options.segment && !rule[options.segment]) {
      // console.log("skipping rule because of missing segment");
      continue;
    }

    if (Array.isArray(rule.placeIds) && rule.placeIds.length > 0) {
      if (options.placeId && !rule.placeIds.includes(options.placeId)) {
        // console.log("skipping rule because of place id");
        continue; // not relevant for this place
      }
    }

    if (Array.isArray(rule.categoryIds) && rule.categoryIds.length > 0) {
      if (options.catId && !rule.categoryIds.includes(options.catId)) {
        // console.log("skipping rule because of category id");
        continue; // not relevant for this category}
      }
    }

    if (Array.isArray(rule.sessionTypes) && rule.sessionTypes.length > 0) {
      if (
        options.sessionType &&
        !rule.sessionTypes.includes(options.sessionType)
      ) {
        // console.log("skipping rule because of session type");
        continue; // not relevant for this session type
      }
    }

    // we passed all the checks, this tax is exempted,
    // but we need to make sure it's not an empty rule so we return a boolean
    // which represents if we met a valid rule
    return rule;
  }

  return null;
};

export const uniqueArray = <T = any>(arr: T[]) =>
  Array.from(new Set(arr)) as T[];

export const sortNumericNames = (splitNames, a, b) => {
  const as = splitNames(a);
  const bs = splitNames(b);

  if (as.segment < bs.segment) {
    return -1;
  } else if (as.segment > bs.segment) {
    return 1;
  }

  if (as.number < bs.number) {
    return -1;
  } else if (as.number > bs.number) {
    return 1;
  }

  return 0;
};

export const calculateSessionCheck = (
  session: TSession,
  options: Partial<CalcCheckOptions> = defaultCheckOptions
) => {
  try {
    if (!session) return null;
    if (!session.orderItems) return null;
    if (!session.tips) return null;
    if (!session.transactions) return null;

    let all =
      options.scope === CheckScope.pending
        ? calculateSessionCheck(session, {
            liableId: options.liableId,
            scope: CheckScope.all,
          })
        : null;

    const opts = { ...defaultCheckOptions, ...options };

    const { settings, meta } = session;
    const businessTaxes: TBusinessTax[] = settings.taxes || [];
    const taxableIndex: Record<string, Big> = {};
    const discounts: Record<string, Big> = {};
    const taxRates: Record<string, number> = {};

    let tax = Big(0);
    let charged = Big(0);
    let authorized = Big(0);
    let total = Big(0);
    let subtotal = Big(0);
    let tips = Big(0);
    let serviceCharge = Big(0);
    let delivery = Big(0);
    let amount = Big(0);
    let discounted = Big(0);
    let change = Big(0);

    let taxes = [] as CheckTaxType[];

    for (let ct of businessTaxes) {
      taxRates[ct.id] = ct.rate;
    }

    let hasAtLeastOneEligbleOrderItem = false;
    const ingoing = isSessionIngoing(session.type);

    /** Order Items, Discounts */
    for (let oi of session.orderItems) {
      if (IGNORE_ORDER_ITEM_STATUS.indexOf(oi.status!) !== -1) continue;
      const orderItemId = oi.id || oi.meta?.localId!;

      // helpers
      const liableId = oi.liableId!;

      const consumer: boolean =
        !liableId || session.consumerIds?.includes(liableId);

      const pending = oi.status === OrderItemStatus.PENDING;
      const scope = pending ? CheckScope.pending : CheckScope.eligble;

      const matched = matchCheckOptions(
        {
          scope,
          liableId,
          consumer,
        },
        opts
      );

      if (!matched) {
        continue;
      }

      if (oi.cost === undefined) {
        throw new Error("order item cost is missing");
      }

      let itemCost = Big(oi.cost).round(2);
      const itemAmount = oi.unit ? oi.amount : 1;

      amount = amount.add(itemAmount);
      hasAtLeastOneEligbleOrderItem = true;

      if (itemCost.lte(0)) {
        continue;
      }

      if (Array.isArray(session.discounts)) {
        for (let discount of session.discounts) {
          if (discount.canceled) continue;

          if (
            Array.isArray(discount.settings.orderItems) &&
            !discount.settings.orderItems.includes(orderItemId) // or specific
          ) {
            // console.log("discount not for order item", discount, orderItemId);
            // discount specific for order items, current order item irrelevant
            continue;
          } else if (
            discount.promoId &&
            discount.settings.orderItems?.length === 0
          ) {
            // if it's a promo, we need to make sure order id is included.
            continue;
          }

          if (!discounts[discount.id!]) discounts[discount.id!] = Big(0);

          if (
            discount.type === discountType.FIX &&
            discount.amount &&
            discount.amount > 0
          ) {
            let discountValue = Big(discount.amount)
              .minus(discounts[discount.id!])
              .round(2);

            if (discountValue.gt(0)) {
              if (discountValue.gte(itemCost)) discountValue = Big(itemCost);

              discounts[discount.id!] =
                discounts[discount.id!].add(discountValue);

              discounted = discounted.add(discountValue);
              itemCost = itemCost.minus(discountValue);
            }
          } else if (
            discount.type === discountType.PERCENT &&
            discount.amount &&
            discount.amount > 0
          ) {
            let discountValue = itemCost.mul(discount.amount).round(2);

            if (discountValue.gt(0)) {
              if (discountValue.gte(itemCost)) discountValue = Big(itemCost);

              discounts[discount.id!] =
                discounts[discount.id!].add(discountValue);
              discounted = discounted.add(discountValue);
              itemCost = itemCost.minus(discountValue);
            }
          }
        }
      }

      subtotal = subtotal.add(itemCost);

      for (let tax of businessTaxes) {
        const exempt = findExamptionRule(tax, {
          catId: oi?.meta?.catId!,
          sessionType: session.type,
          placeId: session.placeId,
          segment: "orderItem",
        });
        if (!exempt) {
          if (!taxableIndex[tax.id]) taxableIndex[tax.id] = Big(0);
          taxableIndex[tax.id] = taxableIndex[tax.id].add(itemCost);
        }
      }
    }

    // TIPS (voluntary)

    if (Array.isArray(session.tips)) {
      // const tipSettings = session.settings.tips;

      for (let tip of session.tips) {
        if (tip.status === TipStatus.CANCELED) continue;
        const eligble = tip.status === TipStatus.ELIGBLE;
        const liableId = tip.liableId;

        const consumer: boolean =
          !liableId || session.consumerIds?.includes(liableId);

        const scope = !eligble ? CheckScope.pending : CheckScope.eligble;

        if (
          !matchCheckOptions(
            {
              scope,
              liableId,
              consumer,
            },
            opts
          )
        ) {
          continue;
        }

        for (let tax of businessTaxes) {
          const exampted = findExamptionRule(tax, {
            placeId: session.placeId,
            sessionType: session.type,
            segment: "tip",
          });
          if (!exampted) {
            const taxId = tax.id;
            if (!taxableIndex[taxId]) taxableIndex[taxId] = Big(0);
            taxableIndex[taxId] = taxableIndex[taxId].add(tip.value);
          }
        }

        tips = tips.add(tip.value);
      }
    }

    // SERVICE CHARGE (non-voluntary)
    const serviceSettings = session.settings.serviceCharge;
    const serviceChargePercent = serviceSettings?.percent!;

    if (
      serviceSettings &&
      !meta.rejectedServiceCharge &&
      ingoing && // is ingoing session type (like seating)
      !isNaN(serviceChargePercent) && // is number
      serviceChargePercent > 0 && // valid percent value
      serviceChargePercent < 1
    ) {
      const fee = subtotal.mul(serviceChargePercent).round(2, Big.roundDown);

      for (let tax of businessTaxes) {
        const exampted = findExamptionRule(tax, {
          placeId: session.placeId,
          sessionType: session.type,
          segment: "serviceCharge",
        });

        if (!exampted) {
          const taxId = tax.id;
          if (!taxableIndex[taxId]) taxableIndex[taxId] = Big(0);
          taxableIndex[taxId] = taxableIndex[taxId].add(fee);
        }
      }

      serviceCharge = fee;
    }

    if (Array.isArray(session.transactions)) {
      for (let i = 0; i < session.transactions.length; i++) {
        const transaction = session.transactions[i];
        const consumer: boolean = session.consumerIds?.includes(
          transaction.byId
        );

        if (
          !matchCheckOptions(
            {
              liableId: transaction.byId!,
              consumer,
            },
            opts
          )
        ) {
          continue;
        }

        if (transaction.status !== transactionStatus.COMPLETE) continue;

        if (
          [transactionAction.CHARGE, transactionAction.REFUND].includes(
            transaction.action
          )
        ) {
          if (transaction.method === paymentMethodTypes.CASH) {
            const payload = transaction.payload as CashTransactionPayload;
            if (payload?.change && payload.changeAmount) {
              change = change.add(payload.changeAmount);
            }
          }

          charged = charged.add(transaction.value);
        } else if (transaction.action === transactionAction.AUTHORIZE) {
          authorized = authorized.add(transaction.value);
        }
      }
    }

    if (session.type === SessionTypes.delivery) {
      if (hasAtLeastOneEligbleOrderItem) {
        // check if total is above free treshold
        if (
          settings.delivery?.freeMin &&
          total.gte(settings.delivery.freeMin)
        ) {
          // delivery is free :)
          delivery = Big(0);
        } else {
          // base delivery
          if (settings.delivery?.baseFee) {
            delivery = Big(settings.delivery?.baseFee);
          }

          // check minimum
          const minOrderSubtotal = settings.delivery?.minOrderSubtotal || 0;

          if (minOrderSubtotal && subtotal.lt(minOrderSubtotal)) {
            const diff = Big(minOrderSubtotal).minus(subtotal);
            // add diff if less than minimum.
            if (diff.gt(0)) {
              // @ts-ignore
              delivery = delivery.add(diff);
            }
          }
        }
      }
    }

    let nonIncludedTaxes = Big(0);

    for (let taxId in taxableIndex) {
      const taxInstance = businessTaxes.find((t) => t.id === taxId);
      const value = calcTax(taxableIndex[taxId], taxRates[taxId]);
      tax = tax.add(value);

      if (!taxInstance?.included)
        nonIncludedTaxes = nonIncludedTaxes.plus(value);

      taxes.push({
        name: taxInstance?.name || taxId,
        value: value.toNumber(),
      });
    }

    const res = {
      subtotal: subtotal.round(2).toNumber(),
      amount: amount.round(2).toNumber(),
      taxes,
      total: 0,
      tips: tips.round(2).toNumber(),
      discounts: Object.keys(discounts).reduce(
        (results, id) => {
          results[id] = discounts[id].round(2).toNumber();
          return results;
        },
        {} as Record<string, number>
      ),
      discounted: discounted.round(2).toNumber(),
      charged: charged.round(2).toNumber(),
      authorized: authorized.round(2).toNumber(),
      delivery: delivery.round(2).toNumber(),
      balance: 0,
      change: change.round(2).toNumber(),
      tax: tax.round(2).toNumber(),
      serviceCharge: serviceCharge.round(2).toNumber(),
      completeable: false,
    };

    res.total = Big(res.subtotal)
      .add(res.tips)
      .add(res.serviceCharge)
      .add(res.delivery)
      .add(nonIncludedTaxes)
      .round(2)
      .toNumber();

    if (all) {
      res.balance = Big(all.balance).plus(all.authorized).round(2).toNumber();
    } else {
      res.balance = Big(res.charged).minus(res.total).round(2).toNumber();
    }

    res.completeable =
      res.balance > 0 || res.authorized > Math.abs(res.balance);

    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const calculateTip = ({
  mode,
  base,
  total,
}: {
  mode: TipModes;
  base: number;
  total: number;
}) => {
  let value = 0;
  if (mode === TipModes.percent) {
    if (base > 0 && total > 0) {
      value = Math.round(total * base * 10) / 10;
    }
  } else if (mode === TipModes.fixed) {
    if (base > 0) {
      value = Math.round(base * 10) / 10;
    }
  } else if (mode === TipModes.complete) {
    if (base > total) {
      value = base - (total || 0);
    }
  } else {
    throw new Error("tip mode not supported.");
  }

  return value;
};

export const comboParser = ({
  combo,
  terminals,
  terminalMap,
  terminalOptions,
}: {
  combo: any[];
  terminals?: { capacity: number; id: string }[];
  terminalMap?: Record<string, { capacity: number }>;
  terminalOptions?: { value: string; label: string; capacity: number }[];
}): [string[], number] => {
  let capacity: number = 0;
  // we need to make sure the combo capacity is enough
  const comboHasCustom =
    combo.length > 2 && typeof combo[combo.length - 1] === "number";

  if (comboHasCustom) {
    const capacity = combo[combo.length - 1] as number;
    return [combo.slice(0, combo.length - 1) as string[], capacity];
  }

  if (terminals) {
    capacity = (combo as string[]).reduce((acc, id) => {
      const terminal = terminals.find((t) => t.id === id);
      if (terminal) acc += terminal.capacity;
      return acc;
    }, 0);
  } else if (terminalMap) {
    capacity = (combo as string[]).reduce((acc, id) => {
      const terminal = terminalMap[id];
      if (terminal) acc += terminal.capacity;
      return acc;
    }, 0);
  } else if (terminalOptions) {
    capacity = (combo as string[]).reduce((acc, id) => {
      const terminal = terminalOptions?.find((t) => t.value === id);
      if (terminal) acc += terminal.capacity;
      return acc;
    }, 0);
  } else {
    console.error(
      "comboParser: terminals, terminalMap, or terminalOptions must be provided"
    );
  }

  return [combo as string[], capacity];
};

export type CheckedSchedule = {
  status: boolean;
  reason?: string;
  closeAt?: Date;
  schedule?: TSchedule;
};

export enum ScheduleReasons {
  NO_SCHEDULE = "NO_SCHEDULE",
}

export type PossibleDate = string | Date | number;

export const getDate = (date: PossibleDate, strict: boolean = false) => {
  if (!date) {
    if (strict) {
      throw new Error(`Invalid date (${JSON.stringify(date)})`);
    } else {
      return null;
    }
  }

  return toDate(date);
};

const parseShortTime = (time: string): [number, number] =>
  time.split(":").map((part) => Number(part)) as [number, number];

/**
 *
 * @param jsWeekday js weekday is from 0 to 6, Sunday is 0, Monday is 1, and so on
 * @returns isoWeekday iso weekday 1 to 7, 1 is Monday and 7 is Sunday
 */

export const createSessionBookingPlaceholders = (
  sessions: TSession[],
  { defaultMinutes = 120 }
) => {
  return sessions
    .filter((s) => s.type === SessionTypes.seat)
    .map((session) => {
      return bookingSchema.cast({
        id: session.id,
        createdAt: session.createdAt,
        startAt: session.createdAt,
        endAt: addMinutes(session.createdAt!, defaultMinutes).toISOString(),
        terminalIds: [session.terminalId],
        sessionId: session.id,
        status: BookingStatus.ARRIVED,
        guests: session.capacity || 1,
      });
    });
};

type CheckScheduleOptions = {
  ignoreHours?: boolean;
  timezone?: string;
};

const checkScheduleOptions: CheckScheduleOptions = {
  ignoreHours: false,
  timezone: DEFAULT_TIMEZONE,
};

const getOpenAndClose = ({
  opens,
  closes,
  at,
  timeZone,
}: {
  opens: string;
  closes: string;
  at: Date;
  timeZone: string;
}) => {
  const [oh, om] = parseShortTime(opens);
  const [ch, cm] = parseShortTime(closes);

  // Convert 'at' to a string in ISO format
  const utc = at.toISOString();

  const offset = getTimezoneOffset(timeZone);

  // Calculate openAt time in the specified timezone
  let openAt = subMilliseconds(
    set(utc, {
      hours: oh,
      minutes: om,
      seconds: 0,
      milliseconds: 0,
    }),
    offset
  );

  // Calculate closeAt time in the specified timezone
  let closeAt = subMilliseconds(
    set(utc, {
      hours: ch,
      minutes: cm,
      seconds: 0,
      milliseconds: 0,
    }),
    offset
  );

  // Adjust the closeAt time if it is before openAt (crosses midnight)
  if (ch < oh || (ch === oh && cm < om)) {
    closeAt = addDays(closeAt, 1);
  }

  return { openAt, closeAt };
};

export const checkSchedule = (
  schedules: TSchedule[],
  date?: PossibleDate,
  opts = checkScheduleOptions
): CheckedSchedule => {
  const options = { ...checkScheduleOptions, ...opts };

  let at = date ? toDate(date) : new Date();

  if (!isValid(at)) {
    console.error(
      `[checkSchedule] Invalid 'at' date (${JSON.stringify(date)})`
    );
    return { status: false };
  }

  if (!Array.isArray(schedules) || schedules.length <= 0)
    return {
      status: true,
      reason: ScheduleReasons.NO_SCHEDULE,
      closeAt: endOfDay(at),
    };

  const timeZone = options.timezone || DEFAULT_TIMEZONE;

  // Convert 'at' to the specified timezone once

  // Filter schedules based on validFrom, validThrough, and daysOfWeek
  let relevantSchedules = schedules.filter((sc) => {
    const validThrough = sc.validThrough
      ? toDate(sc.validThrough, { timeZone })
      : null;
    const validFrom = sc.validFrom ? toDate(sc.validFrom, { timeZone }) : null;

    if (validThrough && isAfter(at, validThrough)) return false;
    if (validFrom && isBefore(at, validFrom)) return false;

    const jsDay = at.getDay();
    const day = JS_WEEKDAYS_INDEX[jsDay];

    if (sc.daysOfWeek && !sc.daysOfWeek.includes(day)) return false;

    return true;
  });

  if (relevantSchedules.length === 0) {
    return { status: false };
  }

  // Check if any schedule has specific dates
  const hasDateSpecificSchedule = relevantSchedules.some(
    (sc) => sc.validThrough || sc.validFrom
  );

  // If there are date-specific schedules, ignore the ones without specific dates
  if (hasDateSpecificSchedule) {
    relevantSchedules = relevantSchedules.filter(
      (sc) => sc.validThrough || sc.validFrom
    );
  }

  // Check opening and closing times
  const openSchedules = relevantSchedules.filter((sc) => {
    if (!sc.opens || !sc.closes) return false;

    const isClosedAllDay =
      sc.closes === HOURS_ALL_DAY_CLOSES.closes &&
      sc.opens === HOURS_ALL_DAY_CLOSES.opens;

    if (isClosedAllDay) return false;

    const isOpenAllDay =
      sc.opens === HOURS_ALL_DAY_OPEN.opens &&
      sc.closes === HOURS_ALL_DAY_OPEN.closes;

    if (isOpenAllDay) return true;

    if (options.ignoreHours) return true;

    const { openAt, closeAt } = getOpenAndClose({
      at,
      timeZone,
      closes: sc.closes,
      opens: sc.opens,
    });

    if (isBefore(at, openAt) || isAfter(at, closeAt)) return false;

    return true;
  });

  if (openSchedules.length === 0) {
    return { status: false };
  }

  // Prioritize schedules with dates or select the first available
  let selectedSchedule =
    openSchedules.find((sc) => sc.validThrough || sc.validFrom) ??
    openSchedules[0];

  const { closeAt } = getOpenAndClose({
    at,
    timeZone,
    closes: selectedSchedule.closes,
    opens: selectedSchedule.opens,
  });

  return { status: true, closeAt, schedule: selectedSchedule };
};

export const calcModifierChange = (
  modifier: TModifier,
  optId: string,
  value: string | number,
  state: any
) => {
  if (modifier.style === modifierStyle.SELECT) {
    const mod = {};

    // @ts-ignore
    const optValue = Math.min(modifier.options.max, value);

    // single, switch to selected, ignore value.
    // @ts-ignore
    if (!isNaN(modifier.options.max) && modifier.options.max <= 1) {
      // @ts-ignore
      for (let option of modifier.options.values) {
        if (option.id === optId) {
          mod[option.id] = optValue;
        } else {
          mod[option.id] = 0;
        }
      }
    } else {
      // is multi;
      let count = 0; // count make sure we are not baypassing total max;

      // @ts-ignore
      for (let option of modifier.options.values) {
        if (option.id === optId) {
          if (
            optValue > option.max ||
            (!isNaN(modifier.options?.["max"]) &&
              count + optValue > modifier.options["max"])
          ) {
            mod[option.id] = option.min;
            count += option.min;
          } else {
            mod[option.id] = optValue;
            count += optValue;
          }
        } else {
          mod[option.id] = state[option.id];
          count += state[option.id];
        }
      }

      // do nothing, return same state as before; we passed max;
      // @ts-ignore
      if (count > modifier.options.max) return state;
      // otherwise, return new state;
      return mod;
    }

    return mod;
  } else if (modifier.style === modifierStyle.VERBAL) {
    return { ...state, [optId]: value };
  }
};

export const previewModifiersCost = (mo, modifiers) => {
  let result = 0;
  for (let mid in mo) {
    for (let oid in mo[mid]) {
      const m = modifiers[mid];
      const o = m?.options?.values?.find((v) => v.id == oid);

      let amount = 0,
        price = 0;

      if (m.style === modifierStyle.SELECT) {
        amount = Number(mo[mid][oid]);
        price = Number(o.price);
      } else if (m.style === modifierStyle.VERBAL) {
        const selected = mo[mid][oid];
        const pos = o.possibilities.find((p) => p.type === selected);
        price = Number(pos.price);
        amount = 1;
      }

      result += price * amount;
    }
  }
  return result;
};

export const previewOrderItemCost = (oi: any, modifiers): number => {
  try {
    if (!oi) return NaN;

    let additionals = previewModifiersCost(oi.modifiers, modifiers);

    for (let sgId in oi.subItemGroups) {
      additionals += Number(oi.subItemGroups[sgId].cost) || 0; // additional price for sub item
      additionals += previewModifiersCost(
        oi.subItemGroups[sgId].modifiers,
        modifiers
      ); // sub item modifiers
    }

    return (oi.ppu + additionals) * oi.amount;
  } catch (error) {
    console.error(error);
    return NaN;
  }
};

export const applyOrderItemModifiersByState = (
  modifiers,
  state,
  oi,
  strict = false
) => {
  const invalids: string[] = [];

  for (let mid in state.modifiers) {
    const modifier = modifiers[mid];
    const data = {};
    let sum = 0;
    for (let moid in state.modifiers[mid]) {
      const value = state.modifiers[mid][moid];
      const o = modifier?.options?.values?.find((v) => v.id == moid);
      let type, cost;
      if (modifier.style === modifierStyle.SELECT) {
        type = Number(value);
        cost = Number(o.price);
        sum += type;
      } else if (modifier.style === modifierStyle.VERBAL) {
        type = value;
        const pos = o.possibilities.find((p) => p.type === value);
        cost = Number(pos.price);
      }
      data[moid] = { type, cost };
    }

    if (modifier.style === modifierStyle.SELECT) {
      if (sum < modifier.options.min) invalids.push(mid);
      if (sum > modifier.options.max) invalids.push(mid);
    }

    oi.modifiers[mid] = data;
  }

  if (strict && invalids.length)
    throw new Error(`Invalid modifiers ${invalids.join(", ")}`);

  return invalids;
};

export const orderItemIs = ({
  orderable,
  status,
  id,
  state,
  liable,
  operator = false,
  type,
}) => {
  const pending = status === OrderItemStatus.PENDING;
  return {
    draft: !id,
    pending,
    mutable: operator
      ? true
      : (pending || !status) &&
        orderable &&
        liable &&
        isSessionAcceptConsumerMutations({ type, state }),
  };
};

export const orderItemCan = ({ is, changed }) => ({
  add: is.mutable && is.draft,
  update: is.mutable && is.pending && changed,
  remove: is.mutable && is.pending,
});

export const canComplete = ({
  documents,
  transactions,
  ended,
  check,
}: {
  documents?: TSessionDocument[];
  transactions?: TSessionTransaction[];
  ended: boolean;
  check: TSession["check"];
}) => {
  if (ended) {
    // if ended, check if transaction made after creating last transaction
    const lastDocument = documents?.reduce(
      (prev, current) => {
        if (
          !prev ||
          (prev.issuedAt &&
            current.issuedAt &&
            prev.issuedAt < current.issuedAt)
        ) {
          return current;
        }
        return prev;
      },
      null as null | TSessionDocument
    );

    const lastTransaction = transactions?.reduce(
      (prev, current) => {
        if (
          !prev ||
          (prev.createdAt &&
            current.createdAt &&
            prev.createdAt < current.createdAt)
        ) {
          return current;
        }
        return prev;
      },
      null as null | TSessionTransaction
    );

    return (
      !!lastDocument &&
      !!lastTransaction &&
      lastDocument.issuedAt! < lastTransaction.createdAt!
    );
  } else {
    // no check available, dont take descision;
    if (!check) {
      return false;
    }

    // check is balanced
    if (check.balance >= 0) return true;

    // the authorized amount will be sufficent.
    if (check.authorized >= Math.abs(check.balance)) return true;

    return false;
  }
};

export const isSessionEnded = (sessionState: SessionState) =>
  SESSION_STATE_ENDED.includes(sessionState);

export const calcSessionStatus = ({
  sessionType,
  sessionState,
  enabled,
  allowed,
  isOperator,
  deliverySchedule,
  schedule,
  session,
  userId,
}: {
  userId?: string;
  sessionType?: any;
  sessionState?: any;
  enabled?: any;
  allowed?: any;
  isOperator?: any;
  deliverySchedule?: any;
  schedule?: any;
  session?: Partial<TSession>;
}) => {
  const pnd = isOperator
    ? { scope: CheckScope.pending, entity: CheckEntity.operator }
    : {
        scope: CheckScope.pending,
        entity: CheckEntity.consumer,
        liableId: userId,
      };

  const type = sessionType || session?.type;
  const state = sessionState || session?.state;

  let promoCodeIds = session?.meta?.promos?.map?.((sp) => sp.promoId) || [];

  const ingoing = isSessionIngoing(type);
  const items: Record<string, number> = {};

  const result = {
    items,

    orderable: false,
    able: isOperator
      ? enabled?.[type as any] !== false // is operator, check operator only.
      : allowed?.[type as any] !== false && // is consumer, check general & allowed to customers
        enabled?.[type as any] !== false,

    schedule: checkSchedule(
      type === SessionTypes.delivery ? deliverySchedule : schedule,
      session?.meta?.targetAt || new Date()
    ),

    voidable: false,

    promoCodeIds,

    ingoing,

    ended: SESSION_STATE_ENDED.includes(state),

    handoff: ingoing
      ? !CONSUMER_ACTIVE_SESSION_STATES.includes(state)
      : state !== SessionState.draft, // consumer not in control anymore;

    completable: false,

    hasPaymentSettings: !!session?.settings?.payments,

    pendings: calculateSessionCheck(session as TSession, pnd),

    authorizedBalance: 0,
    leftPending: 0,
    leftOrder: 0,
    leftCombined: 0,

    lastUpdate: session?.updatedAt,
  };

  if (session) {
    result.completable = canComplete({
      ended: result.ended,
      transactions: session.transactions,
      documents: session.documents,
      check: session.check,
    });

    if (Array.isArray(session.orderItems) && session.orderItems.length) {
      for (let orderItem of session.orderItems) {
        if (
          !orderItem.status ||
          IGNORE_ORDER_ITEM_STATUS.includes(orderItem.status)
        ) {
          continue;
        }
        if (!items[orderItem.itemId]) items[orderItem.itemId] = 0;
        items[orderItem.itemId] += orderItem.amount;
      }
    }

    if (!result.ended) {
      if (
        isOperator &&
        !session.transactions?.length &&
        !session.documents?.length
      ) {
        // as long we didnt have any transactions or documents, we can void the session.
        result.voidable = true;
      }
    }
  }

  if ((isOperator || result.schedule?.status) && result.able) {
    result.orderable = true;
  }

  if (
    result.orderable &&
    session?.meta?.targetAt &&
    isPast(session?.meta?.targetAt)
  ) {
    // cannot set past dates
    result.schedule = {
      status: false,
    };
  }
  const checkBalance = session?.check?.balance || 0;
  const checkAuthorized = session?.check?.authorized || 0;

  result.authorizedBalance = Big(checkBalance).plus(checkAuthorized).toNumber();

  if (result.pendings?.amount) {
    const pendingTotal = result.pendings.total || 0;

    result.leftPending = pendingTotal;
  }

  if (result.authorizedBalance < 0) {
    result.leftOrder = Math.abs(result.authorizedBalance);
  }

  const allCheck = calculateSessionCheck(session as TSession, {
    scope: CheckScope.all,
  });

  result.leftCombined = Big(allCheck?.total || 0)
    .minus(allCheck?.authorized || 0)
    .minus(allCheck?.charged || 0)
    .toNumber();

  return result;
};

export const staticImageBuilder = (url, { width, height }) => {
  if (!url || typeof url !== "string") return null;

  const domain = url.includes("gethe.re")
    ? "gethe.re"
    : url.includes("tableport.io")
      ? "tableport.io"
      : null;

  if (url.indexOf(`https://static.${domain}/`) !== 0) return url;

  if (width && height) {
    let query = "";
    query += width + "x" + height;
    return url.replace(
      `https://static.${domain}/`,
      `https://static.${domain}/images/${query}/`
    );
  }
  return url;
};

// Booking related

const MINUTES_SPACER = 30;
const MAX_SLOT_MINUTES = 30;

export const calcAvailableDates = ({
  schedule,
  maxDaysInAdvance,
  minIsoDate,
}: {
  schedule: any[];
  maxDaysInAdvance: number;
  minIsoDate?: string;
}) => {
  const dates: string[] = [];

  const minDatetime = minIsoDate ? getDate(minIsoDate) : null;
  const todayStartsAt = startOfToday();

  let date = minDatetime ? max([minDatetime, todayStartsAt]) : todayStartsAt;

  for (let i = 0; i < maxDaysInAdvance; i++) {
    if (checkSchedule(schedule, date, { ignoreHours: true })?.status) {
      dates.push(format(date, "yyyy-MM-dd"));
    }

    date = addDays(date, 1);
  }
  return dates;
};

export const calcAvailableDateTimes = ({
  intl,
  place,
  date,
}: {
  intl: any;
  place: TPlace;
  date: Date;
}) => {
  const results: { label: string; value: string }[] = [];
  const schedule = place?.bookings?.settings?.schedule || place?.schedule;
  if (!schedule?.length) return results;

  const bookingSettings = place?.bookings?.settings;
  const minutesSlot =
    bookingSettings?.minutesSlot || DEFAULT_PLACE_BOOKING.minutesSlot!;

  let startAt = startOfDay(date);
  let maxDate = addDays(startAt, 1);

  while (startAt < maxDate) {
    const iso = startAt.toISOString();
    const sc = checkSchedule(schedule, iso);

    if (
      // not past
      !isPast(startAt) &&
      // is open
      sc?.status &&
      // and has enought margin with closing time
      sc?.closeAt &&
      addMinutes(startAt, minutesSlot) <= sc?.closeAt
    ) {
      results.push({
        label: intl.formatTime(iso, { timeStyle: "short" }),
        value: iso,
      });
    }

    startAt = addMinutes(startAt, MINUTES_SPACER);
  }

  return results;
};

export const getNearestSlot = (slots: { value: string }[], time: Date) => {
  let slot: Date | null = null;
  let diff: number | null = null;

  for (let s of slots) {
    const dt = getDate(s.value);
    const df = Math.abs(differenceInMilliseconds(time, dt!));
    if (!slot) {
      slot = dt;
      diff = df;
    }
    if (diff && df < diff) {
      slot = dt;
      diff = df;
    }
  }

  return slot;
};

export const filterItemPromotion = ({
  item,
  promo,
  at,
  promoCodeIds = null,
  discounts,
  timezone = DEFAULT_TIMEZONE,
}: {
  item: TItem;
  promo: TPromo;
  at: string | null;
  promoCodeIds?: string[] | null;
  discounts?: TSessionDiscount[];
  timezone?: string;
}) => {
  if (!item || !promo) {
    // console.log("no item or promo", item, promo);
    return false;
  }

  if (!promo.active) {
    // console.log(promo.id, "promo not active");
    return false;
  }

  const discount = discounts?.find?.((d) => d.promoId === promo.id);

  if (discount?.canceled) return false;

  if (promo.trigger === "manual") {
    if (!discount) {
      // console.log(promo.id, "promo is manual and not in discounts", discounts);
      return false;
    }
  }

  if (!!promo.requires?.code) {
    if (!Array.isArray(promoCodeIds) || !promoCodeIds.includes(promo.id!)) {
      // console.log(promo.id, "promo requires code and not in promo codes");
      return false;
    }
  }

  // console.log({
  //   at,
  //   schedule: promo.schedule,
  // });
  if (at && promo.schedule && promo.schedule.length > 0) {
    if (!checkSchedule(promo.schedule, at, { timezone }).status) {
      // console.log("schedule not match to date");
      // console.log(promo.id, "promo not in schedule");
      return false;
    } else {
      // console.log("schedule match to date");
    }
  }

  if (Array.isArray(promo.target.itemIds) && promo.target.itemIds.length) {
    const excluded = Boolean(promo.target.itemsExcluded);
    const found = promo.target.itemIds.includes(item.id!);

    if (excluded && found) {
      // console.log(promo.id, "promo item excluded and found");
      return false;
    }

    if (!excluded && !found) {
      // console.log(promo.id, "promo item included and not found");
      return false;
    }
  }
  if (
    Array.isArray(promo.target.categoryIds) &&
    promo.target.categoryIds.length
  ) {
    const excluded = Boolean(promo.target.categoriesExcluded);
    const found = item.catId && !!promo.target.categoryIds.includes(item.catId);

    if (excluded && found) {
      // console.log(promo.id, "promo category excluded and found", {
      //   id: item.catId,
      //   ids: promo.target.categoryIds,
      //   exluded: promo.target.categoriesExcluded,
      // });
      return false;
    }

    if (!excluded && !found) {
      // console.log(promo.id, "promo category included and not found");
      return false;
    }
  }

  // console.log("passed promo", { itemName: item.name, promoName: promo.id });
  return true;
};

export const filterItemPromotions = ({
  item,
  promotions,
  at = null,
  promoCodeIds = null,
  discounts,
  timezone = DEFAULT_TIMEZONE,
}: {
  item: TItem;
  promotions: TPromo[];
  at?: string | null;
  promoCodeIds?: string[] | null;
  discounts?: TSessionDiscount[];
  timezone?: string;
}) => {
  if (!item || !promotions) return [];

  return promotions.filter((promo) =>
    filterItemPromotion({
      item,
      promo,
      at,
      promoCodeIds,
      discounts,
      timezone,
    })
  );
};

export const getNameObjInitials = (
  name: TUser["name"] | TBusinessControl["meta"]["name"]
) => [name?.first?.[0], name?.last?.[0]].filter(Boolean).join("");

export const getNameObjFull = (
  name: TUser["name"] | TBusinessControl["meta"]["name"]
) => [name?.first, name?.last].filter(Boolean).join(" ");

export const getNameObjShortName = (
  name: TUser["name"] | TBusinessControl["meta"]["name"]
) => {
  const first = name?.first;
  const last = name?.last;

  if (!last?.length) return first;
  if (first && last?.length) return [first, last[0] + "."].join(" ");

  return "Unknown";
};

// function that returns the short name of the table/bar seat,
// e.g. "Tabel 1" or "Bar 1" => "1"
export const getShortName = (name: string) => {
  const split = name.split(" ");
  const result = split[split.length - 1];
  return result?.length > 0 ? result : name;
};

export const isWaitlist = (booking: TBooking) => {
  return booking.segment === "waitlist";
};

export const isReservation = (booking: TBooking) => {
  return !booking.segment;
};

export const isNotify = (booking: TBooking) => {
  return booking.segment === "notify";
};
