export enum FeeStrategies {
  ONE_TIER = "1T", // Fixed
  TWO_TIERS = "2T", // High and Low tiers

  // monthly
  MONTHLY_SUBSCRIPTION = "MS", // Monthly Location

  // hardware
  HARDWARE = "HW", // Stripe WisePad
}

export enum BookingStatus {
  // INITIAL
  PENDING = "PENDING", // JUST ADDED

  // OPTIONAL MID-STEP
  CONFIRMED = "CONFIRMED", // CONFIRMED BY OPERATOR / CUSTOMER

  // BAD END
  NO_SHOW = "NO_SHOW", // NO SHOW
  CANCELED = "CANCELED", // CANCELED BY CUSTOMER / OPERATOR

  // GOOD END
  ARRIVED = "ARRIVED", // ARRIVED
}

export enum SessionEventTypes {
  message = "MSG", // Message
  cashdrawer = "CD", // Cash Drawer
  print_bill = "PB", // Print Bill

  added_order_items = "AOI", // Added Order Items
  removed_order_items = "ROI", // Removed Order Items
  canceled_order_items = "COI", // Canceled Order Items
  approved_order_items = "AOI", // Approved Order Items
  updated_order_items = "UOI", // Updated Order Items
  transfer_order_items = "TOI", // Transfer Order Items

  added_transaction = "ATR", // Added Transaction
  updated_transaction = "UTR", // Updated Transaction

  cancel_transaction = "CT", // Added Discount

  terminal_switch = "TS", // Switched Terminals
  added_tip = "ATP", // Added Tip
  updated_tip = "UTP", // Updated Tip
  session_created = "SC", // Session Created
  session_reopened = "SRO", // Session Reopened

  void_session = "VS",
  send_order_link = "SOL",
}

export enum LicenseSegmentType {
  // platform fees
  location_monthly = "location_monthly",
  reservations_monthly = "reservations_monthly",
  waitlist_monthly = "waitlist_monthly",
  card_reader_monthly = "card_reader_monthly",

  // hardware fees
  stripe_wisepad_device = "stripe_wisepad_device",

  // processing fees
  ach_credit_transfer = "ach_credit_transfer",
  ach_debit = "ach_debit",
  acss_debit = "acss_debit",
  alipay = "alipay",
  au_becs_debit = "au_becs_debit",
  bancontact = "bancontact",
  card = "card",
  card_present = "card_present",
  eps = "eps",
  giropay = "giropay",
  ideal = "ideal",
  klarna = "klarna",
  multibanco = "multibanco",
  p24 = "p24",
  sepa_debit = "sepa_debit",
  sofort = "sofort",
  stripe_account = "stripe_account",
  wechat = "wechat",
}

const segmentsPerDevice = [LicenseSegmentType.card_reader_monthly];

const segmentsPerLocation = [
  LicenseSegmentType.location_monthly,
  LicenseSegmentType.reservations_monthly,
  LicenseSegmentType.waitlist_monthly,
];

export const licenseSegmentTypesLabels: Record<LicenseSegmentType, string> = {
  location_monthly: "Base Subscription",
  reservations_monthly: "Reservations Subscription",
  waitlist_monthly: "Waitlist Subscription",
  card_reader_monthly: "Card Reader Subscription",
  stripe_wisepad_device: "Stripe WisePad Device",
  ach_credit_transfer: "ACH Credit Transfer",
  ach_debit: "ACH Debit",
  acss_debit: "ACSS Debit",
  alipay: "Alipay",
  au_becs_debit: "AU BECS Debit",
  bancontact: "Bancontact",
  card: "Card Not Present",
  card_present: "Card Present",
  eps: "EPS",
  giropay: "Giropay",
  ideal: "iDEAL",
  klarna: "Klarna",
  multibanco: "Multibanco",
  p24: "P24",
  sepa_debit: "SEPA Debit",
  sofort: "SOFORT",
  stripe_account: "Stripe Account",
  wechat: "WeChat",
};

export const monthlySubscriptionTypes = [
  LicenseSegmentType.location_monthly,
  LicenseSegmentType.reservations_monthly,
  LicenseSegmentType.waitlist_monthly,
  LicenseSegmentType.card_reader_monthly,
];

export const hardwareTypes = [LicenseSegmentType.stripe_wisepad_device];

export const processingTypes = [
  LicenseSegmentType.ach_credit_transfer,
  LicenseSegmentType.ach_debit,
  LicenseSegmentType.acss_debit,
  LicenseSegmentType.alipay,
  LicenseSegmentType.au_becs_debit,
  LicenseSegmentType.bancontact,
  LicenseSegmentType.card,
  LicenseSegmentType.card_present,
  LicenseSegmentType.eps,
  LicenseSegmentType.giropay,
  LicenseSegmentType.ideal,
  LicenseSegmentType.klarna,
  LicenseSegmentType.multibanco,
  LicenseSegmentType.p24,
  LicenseSegmentType.sepa_debit,
  LicenseSegmentType.sofort,
  LicenseSegmentType.stripe_account,
  LicenseSegmentType.wechat,
];

export enum MenuItemTypes {
  item = "IT",
  group = "GR",
  collection = "CL",
}

export enum BusinessReportMetrics {
  ttDur = "ttDur", // time tracking duration

  trValue = "trValue", // transaction value
  trCount = "trCount", // transaction count
  trFee = "trFee", // transaction fee
  trFeePer = "trFeePer", // transaction fee %

  seTotal = "seTotal", // session total
  seSubTotal = "seSubTotal", // session subtotal
  seDiscounted = "seDiscounted", // session discounted

  seTips = "seTips", // session tips
  seServiceCharge = "seServiceCharge", // session service charge
  seTaxes = "seTaxes", // session taxes
  seNet = "seNet", // session net sales (total - tips - service charge - taxes - delivery)

  oiValue = "oiValue", // order item value
  oiAmount = "oiAmount", // order item amount

  seCount = "seCount", // session count
  seCapacity = "seCapacity", // session capacity

  // bookings
  boCount = "boCount", // booking count
  boGuests = "boGuests", // booking guests
  boAvgGuests = "boAvgGuests", // booking average guests

  // sms
  msgCount = "msgCount", // sms count
}

export enum TokenTypes {
  access = "ac",
  refresh = "rf",
  session_invite = "si",
}

export enum AuthTypes {
  Bearer = "Bearer",
}

export enum BusinessReportDimensions {
  month = "month",
  date = "date",
  hour = "hour",
  placeId = "placeId",
  businessId = "businessId",
  userId = "userId",

  trAction = "trAction",

  trBrand = "trBrand",
  trCountry = "trCountry",
  trType = "trType",

  acceptId = "acceptId",
  seType = "seType",
  terminalId = "terminalId",
  withConsumers = "withConsumers",
  itemId = "itemId",
  catId = "catId",
  catType = "catType",

  // bookings
  boStatus = "boStatus",

  // ext message
  country = "country",
  msgType = "msgType",
  msgSegment = "msgSegment",
  msgProvider = "msgProvider",

  capacity = "capacity",
  msgChannel = "msgChannel",

  // discounts
  diType = "diType",
  diPromo = "diPromo",

  byId = "byId",
}

export enum BusinessReportTypes {
  // TAXES = "TAXES",
  TRANSACTIONS = "TRANSACTIONS",
  SESSIONS = "SESSIONS",
  ORDER_ITEMS = "ORDER_ITEMS",
  TIME_TRACKS = "TIME_TRACKS",
  TIPS = "TIPS",

  EXT_MESSAGE = "EXT_MESSAGE",
  DISCOUNTS = "DISCOUNTS",
  BOOKINGS = "BOOKINGS",
  WAITLIST = "WAITLIST",
}

export enum SocketActions {
  SUBSCRIBE_SESSION = "SUBSCRIBE_SESSION",
  UNSUBSCRIBE_SESSION = "UNSUBSCRIBE_SESSION",

  SESSION_UPDATED = "SESSION_UPDATED",
  PRINTING_JOB = "PRINTING_JOB",

  PLACE_TIMETRACK_UPDATED = "PLACE_TIMETRACK_UPDATED",
  PLACE_SESSION_UPDATED = "PLACE_SESSION_UPDATED",
  PLACE_BOOKING_UPDATED = "PLACE_BOOKING_UPDATED",
  PLACE_UPDATED = "PLACE_UPDATED",

  PLACE_STATE_UPDATED = "PLACE_STATE_UPDATED",
  INTERNAL_MESSAGE = "INTERNAL_MESSAGE",

  // CLIENT BOOKING EVENTS
  CLIENT_BOOKING_SUBSCRIBE = "CLIENT_BOOKING_SUBSCRIBE",
  CLIENT_BOOKING_UPDATE = "CLIENT_BOOKING_UPDATE",
}

export enum BookingUpdateActions {
  NOTIFY_TABLE_READY = "NOTIFY_TABLE_READY",
  SET_TERMINALS = "SET_TERMINALS",
  CONFIRM_EST = "CONFIRM_EST",
}

export enum SessionUpdateActions {
  ORDER_ITEM_ADD = "ORDER_ITEM_ADD",
  ORDER_ITEM_UPDATE = "ORDER_ITEM_UPDATE",
  ORDER_ITEM_REMOVE = "ORDER_ITEM_REMOVE",
  ORDER_ITEM_CANCEL = "ORDER_ITEM_CANCEL",
  ORDER_ITEMS_REPRINT = "ORDER_ITEMS_REPRINT",

  ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD",
  APPROVE_SESSION = "APPROVE_SESSION",
  ACCEPT_SESSION = "ACCEPT_SESSION",
  REJECT_SESSION = "REJECT_SESSION",

  UPDATE_SESSION_STATE = "UPDATE_SESSION_STATE",

  TIP_ADD = "TIP_ADD",
  TIP_UPDATE = "TIP_UPDATE",

  DISCOUNT_ADD = "DISCOUNT_ADD",
  DISCOUNT_UPDATE = "DISCOUNT_UPDATE",

  EVENT_UPDATE = "EVENT_UPDATE",

  TRANSACTION_ADD = "TRANSACTION_ADD",
  TRANSACTION_UPDATE = "TRANSACTION_UPDATE",
  TRANSACTION_CANCEL = "TRANSACTION_CANCEL",
  TRANSACTION_SYNC = "TRANSACTION_SYNC",

  OPERATOR_CREATE_PAYMENT_INTENT = "OPERATOR_CREATE_PAYMENT_INTENT",

  TERMINAL_REPLACE = "TERMINAL_REPLACE",

  GET_SNAPSHOT = "GET_SNAPSHOT",
  GET_INVITE_KEY = "GET_INVITE_KEY",

  SUBMITION = "SUBMITION",

  SESSION_CREATED = "SESSION_CREATED",
  SESSION_USER_JOINED = "SESSION_USER_JOINED",
  SESSION_USER_LEAVE = "SESSION_USER_LEAVE",
  SESSION_UPDATE = "SESSION_UPDATE",
  VOID_SESSION = "VOID_SESSION",
  SESSION_TRANSFER_ORDER_ITEMS = "SESSION_TRANSFER_ORDER_ITEMS",
  SESSION_COMPLETE = "SESSION_COMPLETE",
  SESSION_REOPEN = "SESSION_REOPEN",
  USER_FEEDBACK = "USER_FEEDBACK",

  PAYMENT_INTENT_SUCCESSED = "PAYMENT_INTENT_SUCCESSED",
  SEND_ORDER_LINK = "SEND_ORDER_LINK",
}

export enum SessionSubmitIntents {
  ORDER_AND_PAY = "ORDER_AND_PAY",
  PAYMENT = "PAYMENT",
  SYNC = "SYNC", // Retrive and update session.
  QUITE_SYNC = "QUITE_SYNC", // When operator leave order without sending.
  OPEN = "OPEN",
}

// deprecated
export enum TokenTypes {
  DEVICE = "DEVICE",
  USER = "USER",
  ADMIN = "ADMIN",
}

export enum UserTypes {
  ADMIN = "ADMIN",
  GUEST = "GUEST",
  USER = "USER",
}

export enum ItemTypes {
  SINGLE = "SINGLE",
  BUNDLE = "BUNDLE",
}

export enum modifyOptionType {
  EXTRA = "EXTRA",
  WITH = "WITH",
  LESS = "LESS",
  WITHOUT = "WITHOUT",
  SIDE = "SIDE",
}

export enum SESSION_AUDIT_ACTIONS {}

export enum discountType {
  PERCENT = "PERCENT",
  FIX = "FIX",
}

export enum TipModes {
  fixed = "fixed",
  percent = "percent",
  complete = "complete",
}

export enum TipScopes {
  liability = "liability",
  total = "total",
}

export enum permissions {
  apply_discount = "apply_discount",
  manage_orders = "manage_orders",

  control_access = "control_access", // done
  cancel_order_item = "cancel_order_item", // done

  refund_transaction = "refund_transaction",
  cancel_transaction = "cancel_transaction",

  update_order_item_price = "update_order_item_price",

  manage_place_time_tracks = "manage_place_time_tracks", // done
  view_place_time_tracks = "view_place_time_tracks",

  manage_personal_time_tracks = "manage_personal_time_tracks",
  view_personal_time_tracks = "view_personal_time_tracks",

  manage_place_daily = "manage_place_daily",
  view_place_daily = "view_place_daily",

  view_place_tips_reports = "view_place_tips_reports",
  // reservations
  manage_place_bookings = "manage_place_bookings",
}

export enum locales {
  en_US = "en_US",
  en_GB = "en_GB",
  en_AU = "en_AU",
  es_ES = "es_ES",
  // he_IL = "he_IL",
  fr_FR = "fr_FR",
  de_DE = "de_DE",
}

export enum mobileChallengeType {
  DEVICE = "DEVICE",
  USER = "USER",
}

export enum resourceType {
  user = "user",
  place = "place",
  terminal = "terminal",
  collection = "collection",
  item = "item",
  session = "session",
  business = "business",
  device = "device",
  orderItem = "orderItem",
  floorplan = "floorplan",
  integrations = "integrations",
  modifier = "modifier",
  timetrack = "timetrack",
}

export enum modifierStyle {
  VERBAL = "VERBAL",
  SELECT = "SELECT",
}

export enum documentTypes {
  SNAPSHOT = "SNAPSHOT",
  INVOICE = "INVOICE",
  RECEIPT = "RECEIPT",
  COMBINED = "COMBINED",
  REFUND = "REFUND",
}

export enum userAddressTypes {
  HOME = "HOME",
  WORK = "WORK",
  OTHER = "OTHER",
}

export enum integrationTypes {
  PAYMENTS = "PAYMENTS",
  ACCOUNTING = "ACCOUNTING",
}

export enum paymentMethodTypes {
  CARD = "CARD",
  CASH = "CASH",
  OTHER = "OTHER",
}

export enum integrationProviders {
  //
  RIVHIT = "RIVHIT",
  HESHBONIT_ONLINE = "HESHBONIT_ONLINE",
  XERO = "XERO",
  // PAYMENTS
  PAYPLUS_WS = "PAYPLUS_WS",
  UNIPAAS = "UNIPAAS",
  STRIPE_ACCOUNT = "STRIPE_ACCOUNT",
  STRIPE_CONNECT = "STRIPE_CONNECT",
}

export enum OrderItemStatus {
  PENDING = "PENDING", // JUST ADDED
  APPROVED = "APPROVED", // APPROVED ITEM
  ACCEPTED = "ACCEPTED", // ACCEPTED BY BUSINESS - CHARGE PAYMENT METHOD
  PREPARE = "PREPARE",
  READY = "READY", // READY TO PICKUP BY OPERATOR / CONSUMER (PICKUP) / DELIVER (DELIVERY)
  DELIVERING = "DELIVERING",
  REMOVED = "REMOVED",
  SUPPLIED = "SUPPLIED", // SUPPLIED.
  CANCELED = "CANCELED", //
  REJECTED = "REJECTED", // <-- REJECT BY BUSINESS
}

export enum TipStatus {
  PENDING = "PENDING",
  ELIGBLE = "ELIGBLE",
  CANCELED = "CANCELED",
}

export enum PlaceStatus {
  PENDING = "PENDING", // JUST ADDED
  APPROVED = "APPROVED", // APPROVED
  REJECTED = "REJECTED", // <-- REJECT BY BUSINESS
}

export enum PlaceType {
  restaurant = "restaurant",
  quickservice = "quickservice",
}

export enum businessLegalType {
  LTD = "LTD",
  LLC = "LLC",
  INC = "INC",
  OSEK_PATOR = "OSEK_PATOR",
  OSEK_MORSHE = "OSEK_MORSHE",
  NON_PROFIT = "NON_PROFIT",
  PUBLIC_BENEFIT = "PUBLIC_BENEFIT",
  PARTNERSHIP = "PARTNERSHIP",
}

export enum SessionTypes {
  seat = "seat",
  pickup = "pickup",
  delivery = "delivery",
  counter = "counter",
  kiosk = "kiosk",
}

export enum SessionState {
  draft = "DRAFT", // default for pickup & delivery

  canceled = "CANCELED", // CANCELED AUTO
  approved = "APPROVED", // APPROVED BY CUSTOMER

  rejected = "REJECTED", //
  accepted = "ACCEPTED", // ACCEPTED BY OPERATOR

  active = "ACTIVE", // DEFAULT ON SEAT & STAND. FOR PICKUP, DELIVERY COMES AFTER ACCEPTED

  ready = "READY", //
  delivering = "DELIVERING",
  complete = "COMPLETE", //
}

export enum SessionUserState {
  active = "active",
  complete = "complete",
}

export enum SessionUserRole {
  consumer = "consumer",
  operator = "operator",
}

export enum relations {
  terminals = "terminals",
  items = "items",
  collections = "collections",
}

export enum floorPlanShapes {
  rectangle = "rectangle",
  ellipse = "ellipse",
  text = "text",
}

export enum floorPlanShapesTypes {
  terminal = "terminal",
  decor = "decor",
}

export enum weekday {
  mon = 1,
  tue = 2,
  wed = 3,
  thu = 4,
  fri = 5,
  sat = 6,
  sun = 7,
}

export enum UnitType {
  unit = "unit",
}

export enum PrinterTypes {
  escpos = "escpos",
  dotmatrix = "dotmatrix",
  epson = "epson",
  star = "star",
}

export enum PrinterConnection {
  network = "network",
  usb = "usb",
  bt = "bt",
  ble = "ble",
}

export enum printTemplates {
  session_document = "session_document",
  prep_ticket = "prep_ticket",
  place_daily = "place_daily",
  reservations_summary = "reservations_summary",
}

export enum Currencies {
  ILS = "ILS",
  CAD = "CAD",
  USD = "USD",
  GBP = "GBP",
  AUD = "AUD",
  EUR = "EUR",
}

export enum CashDrawAction {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  OPEN = "OPEN",
}

export enum Plans {
  MENU = "MENU",
  ORDERS = "ORDERS",
  CLEARANCE = "CLEARANCE",
  ENTERPRISE = "ENTERPRISE",
}

export enum PlansFeatures {
  MENU = "MENU",
  ORDERS = "ORDERS",
  CLEARANCE = "CLEARANCE",
  ENTERPRISE = "ENTERPRISE",
}

export enum transactionStatus {
  COMPLETE = "COMPLETE",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  PROCCESSING = "PROCCESSING",
  PENDING = "PENDING", // J4 APPROVAL FROM CCC, WAITING FOR SESSION COMPLETE
}

export enum transactionAction {
  CHARGE = "CHARGE",
  REFUND = "REFUND",
  AUTHORIZE = "AUTHORIZE",
}

export enum SystemMode {
  guest = "guest",
  consumer = "consumer",
  operator = "operator",
  admin = "admin",
}

export enum ContractPlatforms {
  esignio = "esignio",
}

export enum BusinessOwnershipTypes {
  franchisor = "franchisor",
  franchisee = "franchisee",
  independent = "independent",
}

export enum ContractStatus {
  pending = "pending",
  signed = "signed",
  canceled = "canceled",
}

export enum CountryCodes {
  Afghanistan = "AF",
  AlandIslands = "AX",
  Albania = "AL",
  Algeria = "DZ",
  AmericanSamoa = "AS",
  Andorra = "AD",
  Angola = "AO",
  Anguilla = "AI",
  Antarctica = "AQ",
  AntiguaAndBarbuda = "AG",
  Argentina = "AR",
  Armenia = "AM",
  Aruba = "AW",
  Australia = "AU",
  Austria = "AT",
  Azerbaijan = "AZ",
  Bahamas = "BS",
  Bahrain = "BH",
  Bangladesh = "BD",
  Barbados = "BB",
  Belarus = "BY",
  Belgium = "BE",
  Belize = "BZ",
  Benin = "BJ",
  Bermuda = "BM",
  Bhutan = "BT",
  Bolivia = "BO",
  BosniaAndHerzegovina = "BA",
  Botswana = "BW",
  BouvetIsland = "BV",
  Brazil = "BR",
  BritishIndianOceanTerritory = "IO",
  BruneiDarussalam = "BN",
  Bulgaria = "BG",
  BurkinaFaso = "BF",
  Burundi = "BI",
  Cambodia = "KH",
  Cameroon = "CM",
  Canada = "CA",
  CapeVerde = "CV",
  CaymanIslands = "KY",
  CentralAfricanRepublic = "CF",
  Chad = "TD",
  Chile = "CL",
  China = "CN",
  ChristmasIsland = "CX",
  CocosKeelingIslands = "CC",
  Colombia = "CO",
  Comoros = "KM",
  Congo = "CG",
  CongoDemocraticRepublic = "CD",
  CookIslands = "CK",
  CostaRica = "CR",
  CoteDIvoire = "CI",
  Croatia = "HR",
  Cuba = "CU",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Djibouti = "DJ",
  Dominica = "DM",
  DominicanRepublic = "DO",
  Ecuador = "EC",
  Egypt = "EG",
  ElSalvador = "SV",
  EquatorialGuinea = "GQ",
  Eritrea = "ER",
  Estonia = "EE",
  Ethiopia = "ET",
  FalklandIslands = "FK",
  FaroeIslands = "FO",
  Fiji = "FJ",
  Finland = "FI",
  France = "FR",
  FrenchGuiana = "GF",
  FrenchPolynesia = "PF",
  FrenchSouthernTerritories = "TF",
  Gabon = "GA",
  Gambia = "GM",
  Georgia = "GE",
  Germany = "DE",
  Ghana = "GH",
  Gibraltar = "GI",
  Greece = "GR",
  Greenland = "GL",
  Grenada = "GD",
  Guadeloupe = "GP",
  Guam = "GU",
  Guatemala = "GT",
  Guernsey = "GG",
  Guinea = "GN",
  GuineaBissau = "GW",
  Guyana = "GY",
  Haiti = "HT",
  HeardIslandMcdonaldIslands = "HM",
  HolySeeVaticanCityState = "VA",
  Honduras = "HN",
  HongKong = "HK",
  Hungary = "HU",
  Iceland = "IS",
  India = "IN",
  Indonesia = "ID",
  Iran = "IR",
  Iraq = "IQ",
  Ireland = "IE",
  IsleOfMan = "IM",
  Israel = "IL",
  Italy = "IT",
  Jamaica = "JM",
  Japan = "JP",
  Jersey = "JE",
  Jordan = "JO",
  Kazakhstan = "KZ",
  Kenya = "KE",
  Kiribati = "KI",
  Korea = "KR",
  Kuwait = "KW",
  Kyrgyzstan = "KG",
  LaoPeoplesDemocraticRepublic = "LA",
  Latvia = "LV",
  Lebanon = "LB",
  Lesotho = "LS",
  Liberia = "LR",
  LibyanArabJamahiriya = "LY",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Macao = "MO",
  Macedonia = "MK",
  Madagascar = "MG",
  Malawi = "MW",
  Malaysia = "MY",
  Maldives = "MV",
  Mali = "ML",
  Malta = "MT",
  MarshallIslands = "MH",
  Martinique = "MQ",
  Mauritania = "MR",
  Mauritius = "MU",
  Mayotte = "YT",
  Mexico = "MX",
  Micronesia = "FM",
  Moldova = "MD",
  Monaco = "MC",
  Mongolia = "MN",
  Montenegro = "ME",
  Montserrat = "MS",
  Morocco = "MA",
  Mozambique = "MZ",
  Myanmar = "MM",
  Namibia = "NA",
  Nauru = "NR",
  Nepal = "NP",
  Netherlands = "NL",
  NetherlandsAntilles = "AN",
  NewCaledonia = "NC",
  NewZealand = "NZ",
  Nicaragua = "NI",
  Niger = "NE",
  Nigeria = "NG",
  Niue = "NU",
  NorfolkIsland = "NF",
  NorthernMarianaIslands = "MP",
  Norway = "NO",
  Oman = "OM",
  Pakistan = "PK",
  Palau = "PW",
  PalestinianTerritory = "PS",
  Panama = "PA",
  PapuaNewGuinea = "PG",
  Paraguay = "PY",
  Peru = "PE",
  Philippines = "PH",
  Pitcairn = "PN",
  Poland = "PL",
  Portugal = "PT",
  PuertoRico = "PR",
  Qatar = "QA",
  Reunion = "RE",
  Romania = "RO",
  RussianFederation = "RU",
  Rwanda = "RW",
  SaintBarthelemy = "BL",
  SaintHelena = "SH",
  SaintKittsAndNevis = "KN",
  SaintLucia = "LC",
  SaintMartin = "MF",
  SaintPierreAndMiquelon = "PM",
  SaintVincentAndGrenadines = "VC",
  Samoa = "WS",
  SanMarino = "SM",
  SaoTomeAndPrincipe = "ST",
  SaudiArabia = "SA",
  Senegal = "SN",
  Serbia = "RS",
  Seychelles = "SC",
  SierraLeone = "SL",
  Singapore = "SG",
  Slovakia = "SK",
  Slovenia = "SI",
  SolomonIslands = "SB",
  Somalia = "SO",
  SouthAfrica = "ZA",
  SouthGeorgiaAndSandwichIsl = "GS",
  Spain = "ES",
  SriLanka = "LK",
  Sudan = "SD",
  Suriname = "SR",
  SvalbardAndJanMayen = "SJ",
  Swaziland = "SZ",
  Sweden = "SE",
  Switzerland = "CH",
  SyrianArabRepublic = "SY",
  Taiwan = "TW",
  Tajikistan = "TJ",
  Tanzania = "TZ",
  Thailand = "TH",
  TimorLeste = "TL",
  Togo = "TG",
  Tokelau = "TK",
  Tonga = "TO",
  TrinidadAndTobago = "TT",
  Tunisia = "TN",
  Turkey = "TR",
  Turkmenistan = "TM",
  TurksAndCaicosIslands = "TC",
  Tuvalu = "TV",
  Uganda = "UG",
  Ukraine = "UA",
  UnitedArabEmirates = "AE",
  UnitedKingdom = "GB",
  UnitedStates = "US",
  UnitedStatesOutlyingIslands = "UM",
  Uruguay = "UY",
  Uzbekistan = "UZ",
  Vanuatu = "VU",
  Venezuela = "VE",
  VietNam = "VN",
  VirginIslandsBritish = "VG",
  VirginIslandsUS = "VI",
  WallisAndFutuna = "WF",
  WesternSahara = "EH",
  Yemen = "YE",
  Zambia = "ZM",
  Zimbabw = "ZW",
}
